.modal {
    background-color: #f5f5f5;
    color: #000;
    border-radius: 0px;
    padding: 20px;
}

.modalOverlay {
    background-color: rgba(0, 0, 0, 0.75);
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
}

.modalHeader, .modalFooter {
    border-bottom: none;
    border-top: none;
}

.modalBody {
    padding-top: 0px;
    padding-bottom: 0px;
}

.modalBody strong {
    font-weight: 500;
}

.modalFooter {
    padding-top: 0px;
}

.modalTitle:after {
    content: "";
    display: block;
    width: 50px;
    padding-top: 5px;
    border-bottom: 1px solid #000;
}

.modalBody ul {
    padding-left: 0px;
    margin-left: -5px;
}

.modalBody li {
    display: inline-block;
    text-align: left;
    margin: 5px;
    font-weight: 600;
    padding: 0px 10px;
    color: #fff;
}

.closeButton {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}

.frontend {
    background-color: #78a22f;
}

.serverCloud {
    background-color: #281e6b;
}

.backendCms {
    background-color: #a94442;
}

.tools, .other {
    background-color: #2f2f2e;
}