.app {
    text-align: center;
    background-color: var(--default-bg);
    min-height: 100vh;
    font-size: calc(10px + 2vmin);
    color: #222;
}

@media (min-width: 768px) {
    .app {
        display: flex;
        flex-wrap: nowrap;
    }
}