.skillContainer {
    vertical-align: top;
    text-align: left;
    font-size: 15px;
    flex-basis: 22%;
    margin: 20px 10px;
}

.skillContainer ul {
    padding-left: 0px;
    margin-left: -5px;
}

.skillContainer li {
    display: inline-block;
    text-align: left;
    margin: 5px;
    font-weight: 600;
    padding: 0px 10px;
}

.frontend li {
    background-color: #78a22f;
}

/* .frontend li:hover {
    background-color: #2b4006;
} */

.serverCloud li {
    background-color: #281e6b;
}

/* .serverCloud li:hover {
    background-color: #0b043a;
} */

.backendCms li {
    background-color: #a94442;
}

/* .backendCms li:hover {
    background-color: #5d1918;
} */

.tools li {
    background-color: #2f2f2e;
}

/* .tools li:hover {
    background-color: #5f5f5e;
} */

@media (max-width: 1024px) {
    .skillContainer {
        flex-basis: 30%;
    }
    .backendCms {
        order: 2;
    }
}

@media (max-width: 768px) {
    .skillContainer {
        flex-basis: 100%;
    }
}