.projectsList {
    display: inline-block;
}

.projectsList li {
    display: inline-block;
    text-align: left;
    margin: 5px;
    font-weight: 400;
    cursor: pointer;
    font-size: 15px;
    border-bottom: 1px solid;
}

.projectsList ul {
    padding-left: 0px;
}