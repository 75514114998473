.form {
    color: #fff;
    font-size: 12px;
    width: 100%;
    margin-top: 30px;
    text-align: center;
}

.form p {
    font-size: 25px;
}

.form input {
    margin: 0px 5px;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #fff;
    outline: none;
    font-size: 15px;
    padding: 5px;
}

.title {
    margin-bottom: 20px;
}

.success {
    color: #fff;
    font-size: 15px;
    text-align: center;
}

.ph {
    display: none;
}

.submitBtn {
    background-color: transparent;
    color: #fff;
    outline: none;
    font-weight: 400;
    border: 1px solid;
    font-size: 15px;
    margin: 20px auto;
    display: block;
}

@media (max-width: 768px) {
    .form {
        margin-top: 20px;
        text-align: left;
    }
    .form input {
        margin-left: 0px;
        width: 100%;
        margin-top: 10px;
    }
    .title {
        margin-bottom: 0px;
    }
}