.title {
    display: block;
    font-weight: 400;
    font-size: 22px;
}

.location {
    font-size: 11px;
    font-weight: 200;
}

.duration {
    font-size: 11px;
    font-weight: 200;
    vertical-align: initial;
    margin-left: 5px;
}

.employer {
    margin-bottom: 20px;
}

.employmentBlock {
    display: inline-block;
}

.roles>div {
    margin-left: 10px;
    font-size: 14px;
}

@media (max-width: 768px) {
    .duration {
        display: block;
        margin-left: 0px;
    }
}