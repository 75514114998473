.rightSidebar {
    display: flex;
    flex: 1;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    text-align: left;
    min-height: 100vh;
    background-color: #000;
    padding: 50px;
}

.proToggle, .qualToggle {
    font-size: 35px;
    color: #fff;
    white-space: normal;
    padding-left: 0px;
    text-align: left;
}

.proToggle:after, .qualToggle:after {
    content: "";
    display: block;
    width: 50px;
    padding-top: 5px;
    border-bottom: 1px solid #fff;
}

.proToggle:focus, .proToggle:hover, .proToggle:active:focus, .qualToggle:focus, .qualToggle:hover, .qualToggle:active:focus {
    text-decoration: none;
    color: #fff;
    outline: none;
}

.accordionContent {
    font-weight: 200;
    color: #f5f5f5;
}

.workBlock {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
}

.workBlock>div:nth-child(1) {
    flex-basis: 400px;
}

.workBlock>div:nth-child(2) {
    flex-basis: 400px;
}

.toggleIcon {
    width: 30px;
    height: 15px;
    fill: #fff;
}

.open {
    -moz-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

.close {
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
}

.rotate {
    -moz-transition: all 0.5s linear;
    -webkit-transition: all 0.5s linear;
    transition: all 0.5s linear;
}

@media(max-width: 768px) {
    .workBlock>div {
        flex-basis: 100%;
    }
    .rightSidebar {
        padding: 30px;
    }
}